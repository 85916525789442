import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import TournamentCreation from '../../components/tournament-creation';

const NewTournamentPage = () => (
  <Layout>
    <h1>Create 10 New Tournaments</h1>
    <div className="stripe-hr thin" />
    <p>
      Want to start smaller? Start with
      {' '}
      <Link
        to="/tournament/new/"
      >
        one tournament instead
      </Link>
      .
    </p>
    <p>
      Not quite ready yet?
      {' '}
      Learn more about how to run a tournament:
      {' '}
      <Link to="/tournament/instructions/">
        Instructions
      </Link>
    </p>
    <h2>Create Ten New Tournaments</h2>
    <TournamentCreation price={9500} />
  </Layout>
);

export default NewTournamentPage;
